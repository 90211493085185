<template>
    
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 轮播图管理 </template>
      <template #input>
        <a-button class="all_boder_btn" @click="toDetail(1, type)"
          >新建</a-button
        >
      </template>
    </HeaderBox>
    <!-- <div class="table-box">
      <a-tabs
        :default-active-key="type"
        @change="callback"
      >
        <a-tab-pane
          key="1"
          tab="app首页"
        ></a-tab-pane>
        <a-tab-pane
          key="2"
          tab="app精选"
        ></a-tab-pane>
        <a-tab-pane
          key="4"
          tab="综合商城"
        ></a-tab-pane>
        <a-tab-pane
          key="3"
          tab="web首页"
        ></a-tab-pane>
      </a-tabs>
    </div> -->

    <a-table
      class="table-template"
      :rowKey="(item) => item.rotationId"
      :columns="columns"
      :data-source="tableData"
      :loading="loading"
      @change="onPage"
      :pagination="{
        total: total,
        current: pageNumber,
        defaultPageSize: pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return `共${total}条`;
        },
      }"
    >
      <template slot="index" slot-scope="item, row, index">
        {{ (pageNumber - 1) * pageSize + index + 1 }}
      </template>
      <!-- 主图 -->
      <template slot="img" slot-scope="text">
        <viewer>
          <img class="picture" :src="text" title="点击放大预览" alt="" />
        </viewer>
      </template>
      <!-- 标题 -->
      <template slot="titleH4" slot-scope="text">
        <span>{{ text ? text : "-" }}</span>
      </template>
      <!-- 标题 -->
      <template slot="link" slot-scope="text">
        <span>{{ text ? text : "-" }}</span>
      </template>
      <!-- 状态 -->
      <template slot="status" slot-scope="text">
        <span
          class="state_all"
          :class="text ? 'state_color_green' : 'state_color_red'"
          >{{ text ? "已启用" : "已停用" }}</span
        >
      </template>
      <template slot="operation" slot-scope="text, record, index">
        <span class="blueText">
          <span v-show="index != 0" @click="onMove(record, index, 'up')"
            >上移 |
          </span>
          <span
            v-show="index != tableData.length - 1"
            @click="onMove(record, index, 'down')"
            >下移 |
          </span>
          <span @click="onStop(record)">{{
            record.status ? "停用" : "启用"
          }}</span>
          | <span @click="toDetail(2, type, record.rotationId)">编辑</span> |
          <span @click="onDel(record.rotationId)">删除</span>
        </span>
      </template>
    </a-table>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "序号",
    width: "60px",
    align: "center",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "轮播图",
    align: "center",
    dataIndex: "img",
    scopedSlots: { customRender: "img" },
  },
  {
    title: "标题",
    align: "center",
    dataIndex: "title",
    scopedSlots: { customRender: "titleH4" },
  },
  {
    title: "链接",
    align: "center",
    dataIndex: "link",
    scopedSlots: { customRender: "link" },
  },
  {
    title: "创建时间",
    align: "center",
    width: "175px",
    dataIndex: "createTime",
  },
  {
    title: "状态",
    align: "center",
    width: "80px",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    align: "center",
    width: "160px",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  // 可用组件的哈希表
  components: { HeaderBox }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      type: 5, //类型   
      columns,
      tableData: [],
      loading: false,
      total: 0,
      pageNumber: 1, //页码
      pageSize: 10, // 页数
    };
  }, // 事件处理器
  methods: {
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getManageList();
    },
    callback(e) {
      this.type = e;
      this.getManageList();
    },
    toDetail(operation, type, id) {
      this.$router.push({
        path: "/admin/Mechanism/CarouselChartDetail",
        query: { operation: operation, type: type, id: id },
      });
    },
    // 上-下移动
    onMove(e, index, move) {
      let secondRotationId = null; // 需要换掉的id
      if (move == "up") {
        // 执行上移操作
        secondRotationId = this.tableData[index - 1].rotationId;
      } else {
        // 执行下移操作
        secondRotationId = this.tableData[index + 1].rotationId;
      }
      this.loading = true;
      this.$ajax({
        method: "put",
        url:
          "/hxclass-management/rotation/move?firstRotationId=" +
          e.rotationId +
          "&secondRotationId=" +
          secondRotationId,
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 200 && res.success) {
            this.getManageList();
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error) {
            this.$message.warning("系统错误");
          }
        });
    },
    // 删除
    onDel(id) {
      let _this = this;
      this.$confirm({
        title: "确定删除该数据吗?",
        okText: "确认",
        onOk() {
          _this.loading = true;
          _this
            .$ajax({
              url: "/hxclass-management/rotation/" + id,
              method: "DELETE",
            })
            .then((res) => {
              _this.loading = false;
              if (res.code == 200 && res.success) {
                _this.$message.success("删除成功");
                _this.getManageList();
              }
            });
        },
        onCancel() {},
      });
    },
    // 控制 启用/禁用
    onStop(e) {
      this.loading = true;
      this.$ajax({
        method: "put",
        url: "/hxclass-management/rotation/",
        params: {
          rotationId: e.rotationId,
          status: e.status ? 0 : 1,
        },
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 200 && res.success) {
            this.getManageList();
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error) {
            this.$message.warning("系统错误");
          }
        });
    },
    getManageList() {
      this.loading = true;
      this.$ajax({
        method: "get",
        url: "/hxclass-management/rotation/" + this.type,
        params: {
          type: 4
        }
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 200 && res.success) {
            this.tableData = res.data;
          } else {
            this.tableData = [];
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error) {
            this.$message.warning("系统错误");
          }
        });
    },
  }, // 生命周期-实例创建完成后调用
  created() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
  }, // 生命周期-实例挂载后调用
  mounted() {
    this.getManageList(); // 获取列表数据
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  min-height: 100%;

  // .table-box {
  //   display: flex;
  //   justify-content: space-between;

  //   /deep/ .ant-tabs-bar {
  //     border-bottom: none;
  //   }

  //   /deep/.ant-tabs-bar {
  //     margin: 0;
  //   }

  //   .all_boder_btn {
  //     margin-top: 10px;
  //   }
  // }
  .picture {
    width: 80px;
    cursor: pointer;
  }
}
// .blueText{
//   span{
//     cursor: pointer;
//   }
// }
</style>
