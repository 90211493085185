var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 轮播图管理 ")]},proxy:true},{key:"input",fn:function(){return [_c('a-button',{staticClass:"all_boder_btn",on:{"click":function($event){return _vm.toDetail(1, _vm.type)}}},[_vm._v("新建")])]},proxy:true}])}),_c('a-table',{staticClass:"table-template",attrs:{"rowKey":function (item) { return item.rotationId; },"columns":_vm.columns,"data-source":_vm.tableData,"loading":_vm.loading,"pagination":{
      total: _vm.total,
      current: _vm.pageNumber,
      defaultPageSize: _vm.pageSize,
      showSizeChanger: true,
      showTotal: function (total, range) {
        return ("共" + total + "条");
      },
    }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, index){return [_vm._v(" "+_vm._s((_vm.pageNumber - 1) * _vm.pageSize + index + 1)+" ")]}},{key:"img",fn:function(text){return [_c('viewer',[_c('img',{staticClass:"picture",attrs:{"src":text,"title":"点击放大预览","alt":""}})])]}},{key:"titleH4",fn:function(text){return [_c('span',[_vm._v(_vm._s(text ? text : "-"))])]}},{key:"link",fn:function(text){return [_c('span',[_vm._v(_vm._s(text ? text : "-"))])]}},{key:"status",fn:function(text){return [_c('span',{staticClass:"state_all",class:text ? 'state_color_green' : 'state_color_red'},[_vm._v(_vm._s(text ? "已启用" : "已停用"))])]}},{key:"operation",fn:function(text, record, index){return [_c('span',{staticClass:"blueText"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(index != 0),expression:"index != 0"}],on:{"click":function($event){return _vm.onMove(record, index, 'up')}}},[_vm._v("上移 | ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(index != _vm.tableData.length - 1),expression:"index != tableData.length - 1"}],on:{"click":function($event){return _vm.onMove(record, index, 'down')}}},[_vm._v("下移 | ")]),_c('span',{on:{"click":function($event){return _vm.onStop(record)}}},[_vm._v(_vm._s(record.status ? "停用" : "启用"))]),_vm._v(" | "),_c('span',{on:{"click":function($event){return _vm.toDetail(2, _vm.type, record.rotationId)}}},[_vm._v("编辑")]),_vm._v(" | "),_c('span',{on:{"click":function($event){return _vm.onDel(record.rotationId)}}},[_vm._v("删除")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }